import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

const CaseStudyList = ({ data }: any) => {
  //  console.log("CaseStudyList", data)
  const { title, summary, thumbImg, thumbImgAlt } = data?.frontmatter
  return (
    <div className=" case-study-tile ">
      <div className="picture">
        <img
          src={thumbImg?.publicURL}
          alt={thumbImgAlt}
          className="img-fluid w-100"
        />
      </div>
      <div>
        <Link to={`/our-work/${data?.slug}`} className="text-decoration-none">
          {" "}
          <h3>{title}</h3>
        </Link>
      </div>
      <p>{summary}</p>

      <Link to={`/our-work/${data?.slug}`} className="learn-more-btn">
        <Icon.PlusCircle /> Know More
      </Link>
    </div>
  )
}

export default CaseStudyList
